import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { defaultColor } from '../../../styles/theme/index';
import AdvancedSearch from './AdvancedSearch';

function Header({
  assignmentId,
  setPage,
  homeSystem,
  isLoading,
  onClose,
  lastAdvancedSearchOptions,
  currentAdvancedSearchOptions,
  setCurrentAdvancedSearchOptions,
}) {
  const { milesRadius } = currentAdvancedSearchOptions;
  const hasSearchChanged = Object.entries(currentAdvancedSearchOptions).every(
    ([key, value]) => lastAdvancedSearchOptions[key] === value
  );
  const isErrored = milesRadius === '';
  const theme = useTheme();
  return (
    <Box css={{ padding: '16px', backgroundColor: defaultColor }}>
      <Stack
        data-testid="matched-associates-header"
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h6">Matched Associates</Typography>
          <Typography
            component="p"
            id="assignment_id_and_home_system"
            variant="overline"
            css={{
              color: theme.palette.text.secondary,
              lineHeight: '13.8px',
              letterSpacing: 0,
            }}
          >
            {`for assignment id ${assignmentId} ${homeSystem}`}
          </Typography>
        </Box>
        <IconButton
          id="suggest-associate-drawer-close-button"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <AdvancedSearch
        currentAdvancedSearchOptions={currentAdvancedSearchOptions}
        setCurrentAdvancedSearchOptions={setCurrentAdvancedSearchOptions}
        lastAdvancedSearchOptions={lastAdvancedSearchOptions}
        milesRadius={milesRadius}
        isLoading={isLoading}
        isErrored={isErrored}
        hasSearchChanged={hasSearchChanged}
        setPage={setPage}
      />
    </Box>
  );
}

export default Header;
