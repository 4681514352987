import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import SearchAssociate from '../../JobTable/components/SearchAssociate';
import { NO_ASSOCIATE } from '../constants';

function AssociateEdit({ selectedAssociate, setSelectedAssociate, isLoading }) {
  const [associateEdit, setAssociateEdit] = useState(false);
  return associateEdit ? (
    <SearchAssociate
      id="associate_search"
      label="Associate"
      disabled={isLoading}
      value={selectedAssociate.name}
      onChange={(associate) => {
        setSelectedAssociate({
          name: associate.label,
          ids: [associate.id],
          jobIds: selectedAssociate.jobIds,
        });
      }}
      shouldStopDisable
      onClear={() => {
        setSelectedAssociate({
          ...NO_ASSOCIATE,
          jobIds: selectedAssociate.jobIds,
        });
      }}
      onFocus={(event) => {
        setTimeout(() => {
          event.target.select();
        }, 0);
      }}
      autoFocus
      size="medium"
    />
  ) : (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Typography variant="subtitle3">Associate</Typography>
        <Typography id="current_assigned_associate" variant="body1">
          {selectedAssociate.name}
        </Typography>
      </Box>
      <Button
        id="batch_edit_associate"
        variant="text"
        onClick={() => setAssociateEdit(true)}
        disabled={isLoading}
      >
        Edit
      </Button>
    </Box>
  );
}

export default AssociateEdit;
