import { datadogLogs } from '@datadog/browser-logs';
import HTTP from '../../core/http';

export function getJobDetails(id, cancelToken) {
  return new Promise((resolve, reject) => {
    HTTP.get('jobs/central', {
      params: {
        id,
      },
      cancelToken,
    })
      .then((response) => {
        try {
          if (response.data.results.length === 1 && response.data.results[0]) {
            resolve(response.data.results[0]);
          } else {
            reject(new Error('No matching results'));
          }
        } catch (error) {
          reject(error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateSchedule(jobId, updatedDatetime, ignoreWarnings = false) {
  return new Promise((resolve, reject) => {
    HTTP.put(`/jobs/${jobId}/update-schedule/`, {
      job_start_after: updatedDatetime,
      ignore_warnings: ignoreWarnings,
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getJobDeclineReason(jobId) {
  return new Promise((resolve, reject) => {
    HTTP.get(`/job_offers/job/${jobId}/last-declined/`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          datadogLogs.logger.error(
            `Received 404 on getting Decline Reason for jobId: ${jobId}`
          );
        }
        reject(new Error('Error retrieving decline reason'));
      });
  });
}
