import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { isFunction } from 'lodash';

export default function AsyncAutocomplete({
  value,
  getResourcesDebounce,
  noOptionsText,
  isActive,
  sx,
  onChange,
  label,
  disabled,
  renderOption,
  id,
  shouldStopDisable,
  onClear,
  onFocus,
  autoFocus,
  size,
}) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [disabledOnChange, setDisabledOnChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const getResultsDebounce = useMemo(
    () =>
      debounce((debounceInputValue, callback) => {
        getResourcesDebounce(debounceInputValue, callback);
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    setLoading(true);

    getResultsDebounce(inputValue, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, getResultsDebounce]);

  return (
    <Autocomplete
      size={size}
      disableClearable={!isFunction(onClear)}
      forcePopupIcon={false}
      sx={sx}
      filterOptions={(x) => x}
      disabled={disabled || disabledOnChange}
      loading={loading}
      options={options}
      includeInputInList
      isOptionEqualToValue={(option, selectedOption) =>
        option.id === selectedOption.id
      }
      disablePortal
      id={id}
      filterSelectedOptions
      value={value}
      blurOnSelect
      clearOnBlur
      noOptionsText={inputValue.length >= 3 ? noOptionsText : ''}
      onChange={(event, newValue, reason) => {
        setOptions(newValue ? [newValue, ...options] : options);
        if (onClear && reason === 'clear') {
          onClear();
        }
        if (newValue) {
          setDisabledOnChange(!shouldStopDisable);
          onChange(newValue, () => {
            setDisabledOnChange(false);
          });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onOpen={() => isActive && isActive(true)}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          onFocus={onFocus}
          label={label}
        />
      )}
      renderOption={renderOption || null}
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
    />
  );
}
