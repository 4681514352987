import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import { useTheme } from '@mui/material/styles';
import BatchModeActions from 'components/BatchModeActions';
import { useParams } from 'react-router-dom';
import { ViewBatchesButton } from './style';
import ViewBatchDrawer from '../ViewBatchDrawer';
import BatchEditDrawer from '../../components/BatchEditDrawer';

function BatchActionBar() {
  const isFilterDrawerOpen = useSelector(
    (state) => state.filters.visibility.isOpen
  );
  const { batchId } = useParams();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);

  const shouldMoveViewBatchesButton = batchId ? false : isFilterDrawerOpen;

  const theme = useTheme();

  const onBatchEditClose = () => {
    setIsEditOpen(false);
  };

  const onViewClose = () => {
    setIsViewOpen(false);
  };

  return (
    <>
      <Box css={{ padding: '8px 16px' }} id="batch_action_bar">
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <BatchModeActions setIsEditOpen={setIsEditOpen} />
          <Box>
            <ViewBatchesButton
              isOpen={shouldMoveViewBatchesButton}
              id="view_batches"
              variant="outlined"
              size="medium"
              css={{ color: theme.palette.primary.main }}
              onClick={() => setIsViewOpen(true)}
            >
              VIEW BATCHES
              <LayersIcon fontSize="small" css={{ marginLeft: '8px' }} />
            </ViewBatchesButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <BatchEditDrawer isOpen={isEditOpen} onClose={onBatchEditClose} />
      <ViewBatchDrawer isOpen={isViewOpen} onClose={onViewClose} />
    </>
  );
}

export default BatchActionBar;
