import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/system';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { utcToZonedTime } from 'date-fns-tz';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers-pro';
import { format, isValid } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { defaultColor } from '../../styles/theme';
import { sendBatchRequest } from '../../services/batch';
import {
  setBatchId,
  setBatchJobs,
  setIsSelectAllPages,
  setSelectAllPageCount,
} from '../../state/ducks/staffingWorkPage/actions';
import {
  convertToMinutes,
  determineAssignmentsSelectedLabel,
  determineAssociateToDisplay,
  determineDateToDisplay,
  determineDurationToDisplay,
  determineMutation,
  determineRequest,
  determineTimeToDisplay,
  isSaveDisabled,
} from './utils';
import AssociateEdit from './components/associateEdit';
import { NO_ASSOCIATE } from './constants';
import { removeNonNumeric } from '../../services/text';
import { VARIANTS } from '../../constants';

function BatchEditDrawer({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [batchName, setBatchName] = useState('');
  const [dateEdit, setDateEdit] = useState(false);
  const [timeEdit, setTimeEdit] = useState(false);
  const [durationEdit, setDurationEdit] = useState(false);
  const [selectedAssociate, setSelectedAssociate] = useState(NO_ASSOCIATE);
  const [initialAssociate, setInitialAssociate] = useState(selectedAssociate);
  const {
    batchJobs,
    isSelectAllPages,
    isSelectAllPagesLoading,
    selectAllPagesCount,
    batchId,
  } = useSelector((state) => state.staffingWorkPage.batch);
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const isBatchModeOn = Array.from(centralMode.values())[0] === 'batch';
  const isAutoModeOn = Array.from(centralMode.values())[0] === 'auto';

  const [initialDate, setInitialDate] = useState({ view: '', edit: null });
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [initialTime, setInitialTime] = useState({ view: '', edit: null });
  const [selectedTime, setSelectedTime] = useState(initialDate);
  const [initialDurationHours, setInitialDurationHours] = useState('');
  const [selectedDurationHours, setSelectedDurationHours] =
    useState(initialDurationHours);
  const [initialDurationMinutes, setInitialDurationMinutes] = useState('');
  const [selectedDurationMinutes, setSelectedDurationMinutes] = useState(
    initialDurationMinutes
  );
  const [durationView, setDurationView] = useState('');
  const batchJobsCount = batchJobs.length;

  const countToDisplay =
    selectAllPagesCount !== 0 ? selectAllPagesCount : batchJobsCount;
  const assignmentsSelectedLabel =
    determineAssignmentsSelectedLabel(countToDisplay);
  const isDurationHoursZeroOrEmpty =
    selectedDurationHours === '0' || selectedDurationHours === '';
  const isDurationMinutesZero = selectedDurationMinutes === '0';
  const isDurationMinutesEmpty = selectedDurationMinutes === '';
  const isErrored =
    durationEdit &&
    ((isDurationHoursZeroOrEmpty && isDurationMinutesZero) ||
      isDurationMinutesEmpty);
  const theme = useTheme();
  const batchJobIds = batchJobs.map((batchJob) => batchJob.id);
  const params = {
    associate: {
      old: initialAssociate,
      new: selectedAssociate,
    },
    startDate: {
      old: initialDate.edit
        ? format(initialDate.edit, 'yyyy-MM-dd')
        : initialDate.edit,
      new: selectedDate.edit
        ? format(selectedDate.edit, 'yyyy-MM-dd')
        : selectedDate.edit,
    },
    startTime: {
      old: initialTime.edit
        ? format(initialTime.edit, 'HH:mm')
        : initialTime.edit,
      new: selectedTime.edit
        ? format(selectedTime.edit, 'HH:mm')
        : selectedTime.edit,
    },
    duration: {
      old: convertToMinutes(initialDurationHours, initialDurationMinutes),
      new: convertToMinutes(selectedDurationHours, selectedDurationMinutes),
    },
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSelectedAssociate(initialAssociate);
      setDateEdit(false);
      setSelectedDate(initialDate);
      setTimeEdit(false);
      setSelectedTime(initialTime);
      setDurationEdit(false);
      setSelectedDurationHours(initialDurationHours);
      setSelectedDurationMinutes(initialDurationMinutes);
      setBatchName('');
    }, theme.transitions.duration.leavingScreen);
  };

  useEffect(() => {
    if (batchJobs.length > 0) {
      const associate = determineAssociateToDisplay(
        batchJobs,
        isSelectAllPages
      );
      setInitialAssociate(associate);
      setSelectedAssociate(associate);
      const dateToDisplay = determineDateToDisplay(
        batchJobs.map((batchJob) => {
          const timeZone = batchJob.store_location_timezone;
          return utcToZonedTime(batchJob.job_start_after, timeZone);
        }),
        isSelectAllPages
      );
      setInitialDate(dateToDisplay);
      setSelectedDate(dateToDisplay);

      const timeToDisplay = determineTimeToDisplay(
        batchJobs.map((batchJob) => {
          const timeZone = batchJob.store_location_timezone;
          return utcToZonedTime(batchJob.job_start_after, timeZone);
        }),
        isSelectAllPages
      );
      setInitialTime(timeToDisplay);
      setSelectedTime(timeToDisplay);

      const durationToDisplay = determineDurationToDisplay(
        batchJobs.map((batchJob) => batchJob.estimated_time),
        isSelectAllPages
      );
      setInitialDurationHours(durationToDisplay.edit.hours);
      setSelectedDurationHours(durationToDisplay.edit.hours);
      setInitialDurationMinutes(durationToDisplay.edit.minutes);
      setSelectedDurationMinutes(durationToDisplay.edit.minutes);
      setDurationView(durationToDisplay.view);
    }
  }, [batchJobs, isSelectAllPages]);

  const dispatchSuccessToast = (name) => {
    const prettyName = (
      <Typography variant="subtitle2" as="span" color="white">
        {name}
      </Typography>
    );
    enqueueSnackbar(
      <Typography variant="body2" as="span" color="white">
        Your batch, {prettyName}, has been created and is processing.
      </Typography>,
      { variant: VARIANTS.info }
    );
  };

  const saveBatch = () => {
    const request = determineRequest(
      `/bulk/batch/${determineMutation(params.associate, isBatchModeOn)}`,
      params,
      batchJobIds,
      batchId,
      !batchName
        ? `${isAutoModeOn ? 'AI Assist' : 'Batch'} ${format(new Date(), 'MM/dd/yyyy hh:mm:ss a')}`
        : batchName
    );
    setIsLoading(true);
    sendBatchRequest(request)
      .then((name) => {
        handleClose();
        dispatchSuccessToast(name);
        dispatch(setBatchJobs([]));
        dispatch(setIsSelectAllPages(false));
        dispatch(setBatchId(null));
        dispatch(setSelectAllPageCount(0));
        setIsLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar(error.detail || 'Your batch has failed.', {
          variant: VARIANTS.error,
        });
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      anchor="right"
      onClose={() => {
        handleClose();
      }}
      open={isOpen}
      css={{ zIndex: theme.zIndex.drawer + 1 }}
      PaperProps={{
        id: 'batch_edit_drawer',
        sx: {
          width: '400px',
          display: 'flex',
          justifyContent: 'space-between',
        },
      }}
    >
      <Box>
        <Box css={{ padding: '16px' }}>
          <Stack
            direction="row"
            alignItems="flex-start"
            css={{
              justifyContent: 'space-between',
              alignItems: 'center',
              columnGap: '16px',
            }}
            spacing={1}
          >
            <Typography id="batch_edit_header" variant="h6">
              {isBatchModeOn ? 'Edit' : 'AI Assisted Scheduling'}
            </Typography>
            <IconButton
              id="batch_edit_close"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Box>
        <Divider />
        <Box css={{ padding: '16px' }}>
          {isSelectAllPagesLoading ? (
            <Box
              id="batch_assignments_selected"
              css={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <CircularProgress size="30px" />
              <Typography as="span" variant="subtitle3">
                Counting selected assignments
              </Typography>
            </Box>
          ) : (
            <div id="number_of_assignments_selected">
              <Typography as="span" variant="subtitle3">
                {countToDisplay}{' '}
              </Typography>
              <Typography as="span" id="assignments-selected-label">
                {assignmentsSelectedLabel}
              </Typography>
            </div>
          )}
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            css={{ marginBottom: '24px' }}
          >
            {isBatchModeOn
              ? 'Only edit fields that need to be changed.'
              : 'Automatically assign associate to selected unstaffed assignment.'}
          </Typography>
          {isBatchModeOn && (
            <>
              <AssociateEdit
                selectedAssociate={selectedAssociate}
                setSelectedAssociate={setSelectedAssociate}
                isLoading={isLoading}
              />
              {dateEdit ? (
                <DesktopDatePicker
                  autoFocus
                  label="Date"
                  value={selectedDate.edit}
                  disabled={isLoading}
                  onChange={(event) => {
                    setSelectedDate({
                      ...selectedDate,
                      edit: isValid(event) ? event : null,
                    });
                  }}
                  css={{ width: '100%', marginTop: '24px' }}
                  slotProps={{
                    textField: {
                      id: 'batch_edit_date_picker',
                    },
                  }}
                />
              ) : (
                <Box
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '24px',
                  }}
                >
                  <Box>
                    <Typography variant="subtitle3">Date</Typography>
                    <Typography id="current_date" variant="body1">
                      {selectedDate.view}
                    </Typography>
                  </Box>
                  <Button
                    id="batch_edit_date"
                    variant="text"
                    onClick={() => setDateEdit(true)}
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                </Box>
              )}
              {timeEdit ? (
                <DesktopTimePicker
                  autoFocus
                  label="Start Time"
                  value={selectedTime.edit}
                  disabled={isLoading}
                  onChange={(event) => {
                    setSelectedTime({
                      ...selectedTime,
                      edit: isValid(event) ? event : null,
                    });
                  }}
                  css={{ width: '100%', marginTop: '24px' }}
                  slotProps={{
                    textField: {
                      id: 'batch_edit_time_picker',
                    },
                  }}
                />
              ) : (
                <Box
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '24px',
                  }}
                >
                  <Box>
                    <Typography variant="subtitle3">Start Time</Typography>
                    <Typography id="current_time" variant="body1">
                      {selectedTime.view}
                    </Typography>
                  </Box>
                  <Button
                    id="batch_edit_time"
                    variant="text"
                    onClick={() => setTimeEdit(true)}
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                </Box>
              )}
              {durationEdit ? (
                <>
                  <Typography
                    variant="body2"
                    css={{ paddingTop: '16px', paddingBottom: '12px' }}
                  >
                    Duration
                  </Typography>
                  <Box
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                    }}
                  >
                    <Box
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <TextField
                        autoFocus
                        id="batch_edit_duration_hours_picker"
                        type="text"
                        label="Hours"
                        disabled={isLoading}
                        variant="outlined"
                        value={selectedDurationHours}
                        onChange={(event) => {
                          setSelectedDurationHours(
                            removeNonNumeric(event.target.value)
                          );
                        }}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                      />
                      <Divider
                        orientation="horizontal"
                        css={{
                          width: '16px',
                          marginLeft: '8px',
                          marginRight: '8px',
                        }}
                      />
                    </Box>
                    <TextField
                      id="batch_edit_duration_minutes_picker"
                      type="text"
                      label="Minutes"
                      variant="outlined"
                      disabled={isLoading}
                      value={selectedDurationMinutes}
                      onChange={(event) => {
                        setSelectedDurationMinutes(
                          removeNonNumeric(event.target.value)
                        );
                      }}
                      error={isErrored}
                      helperText={isErrored ? 'Minutes are required' : ''}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '24px',
                  }}
                >
                  <Box>
                    <Typography variant="subtitle3">Duration</Typography>
                    <Typography id="current_duration" variant="body1">
                      {durationView}
                    </Typography>
                  </Box>
                  <Button
                    id="batch_edit_duration"
                    variant="text"
                    onClick={() => setDurationEdit(true)}
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box>
        <Box
          css={{
            bottom: 0,
            padding: '16px',
            backgroundColor: defaultColor,
          }}
        >
          <Box>
            <TextField
              id="batch_name_txt_field"
              label="Batch Name (optional)"
              variant="outlined"
              css={{ width: '100%' }}
              onChange={(event) => {
                setBatchName(event.target.value);
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box
          css={{
            padding: '16px',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px',
          }}
        >
          <Button
            id="cancel_btn"
            variant="text"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            id="batch_edit_save"
            variant="contained"
            loading={isLoading}
            disabled={
              isBatchModeOn
                ? isSaveDisabled(params) ||
                  isLoading ||
                  isSelectAllPagesLoading ||
                  isErrored
                : false
            }
            onClick={() => saveBatch()}
          >
            {isBatchModeOn ? 'Save' : 'Submit'}
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
}

export default BatchEditDrawer;
