import { datadogLogs } from '@datadog/browser-logs';
import HTTP from '../../../core/http';
import { setFeatureFlags } from './actions';
import { URL } from '../../../core/config';

export const mapFeatureToggles = (featureToggles) =>
  featureToggles.reduce(
    (accumulator, featureToggle) => ({
      ...accumulator,
      [featureToggle.name]: featureToggle.is_enabled,
    }),
    {}
  );

export const containsFeatureFlag = (flagName, featureFlags) =>
  Object.keys(featureFlags).includes(flagName);

export const fetchFeatureFlag = (flagName) => async (dispatch, getState) => {
  const { featureFlags } = getState();
  if (flagName && !containsFeatureFlag(flagName, featureFlags)) {
    try {
      const response = await HTTP.get(URL.featureFlags, {
        params: {
          flag_name: flagName,
        },
      });
      if (response?.data) {
        dispatch(setFeatureFlags(mapFeatureToggles(response.data)));
      } else {
        throw new Error('response data does not exist');
      }
    } catch (error) {
      datadogLogs.logger.error('Unable to get feature flags', {}, error);
    }
  }
};
