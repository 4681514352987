import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { utcToZonedTime } from 'date-fns-tz';
import { DrawerWrapper } from '../../styles/components';
import Header from './components/Header';
import { getAssociate } from '../../services/user';
import MatchDetails from './components/MatchDetails';
import { DEFAULT_VIEW_ASSOCIATE } from '../../state/ducks/viewAssociate/constants';
import ScheduleCollapsableHeader from './components/ScheduleCollapsableHeader';
import MonthAndWeekdaySelector from './components/MonthDaySelector';
import Error from './components/Error';
import WorkPreferencesCollapsableHeader from './components/WorkPreferencesCollapsableHeader';
import CertificationsCollapsableHeader from './components/CertificationsCollapsableHeader';
import { setViewAssociate } from '../../state/ducks/viewAssociate/actions';
import LoadingSpinner from './components/LoadingSpinner';

function AssociateDetails({ gridApiRef }) {
  const selectedAssociate = useSelector((state) => state.viewAssociate);
  const suggestAssociate = useSelector((state) => state.suggestAssociate);
  const [associate, setAssociate] = useState(null);
  const [errorMessage, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAssign, setIsLoadingAssign] = useState(false);
  const [selectedDay, setSelectedDay] = useState(
    utcToZonedTime(
      suggestAssociate.job.job_start_after,
      suggestAssociate.job.store_location_timezone
    )
  );
  const dispatch = useDispatch();
  const loadAssociate = (id, callBack) => {
    setIsLoading(true);
    getAssociate({ id })
      .then((data) => {
        setAssociate(data[0]);
        setIsLoading(false);

        if (callBack) callBack();
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setError(
            error.message ||
              'There was a problem retrieving the associate record'
          );
          setIsLoading(false);
        }

        setError(error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (suggestAssociate.job.job_start_after) {
      setSelectedDay(
        utcToZonedTime(
          suggestAssociate.job.job_start_after,
          suggestAssociate.job.store_location_timezone
        )
      );
    }
  }, [suggestAssociate]);

  useEffect(() => {
    loadAssociate(selectedAssociate.user_id);
  }, [selectedAssociate.user_id]);

  if (errorMessage) {
    return (
      <Error
        doRetry={() => {
          setError(null);
          loadAssociate(selectedAssociate.user_id);
        }}
        id="associate-match-details-error"
      />
    );
  }
  return (
    <DrawerWrapper id="associate-details">
      {isLoading ? (
        <LoadingSpinner id="associate-details-loading" />
      ) : (
        associate && (
          <>
            <Header
              isLoading={isLoadingAssign}
              associate={associate}
              associateDepartment={selectedAssociate.department}
              onClose={() => {
                dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
              }}
            />
            {selectedAssociate && (
              <>
                {!selectedAssociate.hideMatchDetails && (
                  <MatchDetails
                    exceptions={selectedAssociate.exceptions}
                    gridApiRef={gridApiRef}
                    hideAssignButton={
                      selectedAssociate.fromJobDetails ||
                      selectedAssociate.hideAssignButton
                    }
                    associate={associate}
                    homeSystem={
                      suggestAssociate.job.advantage_source_system_name
                    }
                    jobId={suggestAssociate.job.id}
                    jobExternalIdentifier={suggestAssociate.job.external_id}
                    isLoading={isLoadingAssign}
                    setIsLoading={setIsLoadingAssign}
                  />
                )}
                <MonthAndWeekdaySelector
                  selectedDay={selectedDay}
                  setSelectedDay={setSelectedDay}
                />
                <ScheduleCollapsableHeader
                  associate={associate}
                  job={suggestAssociate.job}
                  selectedDay={selectedDay}
                />
                <WorkPreferencesCollapsableHeader
                  associate={associate}
                  job={suggestAssociate.job}
                  selectedDay={selectedDay}
                />
                <CertificationsCollapsableHeader associate={associate} />
              </>
            )}
          </>
        )
      )}
    </DrawerWrapper>
  );
}

export default AssociateDetails;
