import { isEqual } from 'lodash';
import {
  DEFAULT_SELECTED_FILTERS,
  FILTER_NAMES,
  FILTERS_NAMES_TO_NOT_COUNT,
  FILTERS_TO_COUNT_AS_ONE,
  FILTERS_TO_GROUP,
} from '../constants';

export const determineNumberOfFilters = (selectedFilters) =>
  Object.entries(selectedFilters)
    .filter(([key]) => FILTER_NAMES.includes(key))
    .filter(([key]) => !FILTERS_NAMES_TO_NOT_COUNT.includes(key))
    .reduce(
      (accumulator, [key, filters]) =>
        isEqual(filters, DEFAULT_SELECTED_FILTERS[key])
          ? accumulator
          : [...accumulator, [key, filters]],
      []
    )
    .reduce((accumulator, [key, filters]) => {
      const groupKey = FILTERS_TO_GROUP[key] || key;
      const existingGroup = accumulator.find(
        (filter) => filter[0] === groupKey
      );
      return [
        ...accumulator.filter((filter) => filter[0] !== groupKey),
        [groupKey, existingGroup ? [...existingGroup[1], ...filters] : filters],
      ];
    }, [])
    .reduce(
      (accumulator, [key, filters]) =>
        accumulator +
        (filters.size !== 0 && FILTERS_TO_COUNT_AS_ONE.includes(key)
          ? 1
          : filters.size),
      0
    );

const isDefaultDate = (key, selectedFilters) =>
  isEqual(selectedFilters[key], DEFAULT_SELECTED_FILTERS[key]);

export const isDefaultDates = (selectedFilters) =>
  isDefaultDate('activeRangeStart', selectedFilters) &&
  isDefaultDate('activeRangeEnd', selectedFilters);
