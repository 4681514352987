import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import useDeepCompareEffect from 'use-deep-compare-effect';
import AssociateDrawer from 'containers/AssociateDrawer';
import { setAssignmentsDetailsRowsPerPage } from 'state/ducks/staffingWorkPage/actions';
import * as operations from '../../../../state/ducks/staffingWorkPage/operations';
import { DEFAULT_SORT_ORDERING } from '../../../../state/ducks/staffingWorkPage/constants';
import JobTable from '../../../../components/JobTable/JobTable';

function AssignmentList() {
  const { batchId } = useParams();
  const pageOfJobs = useSelector((state) => state.staffingWorkPage.pageOfJobs);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(DEFAULT_SORT_ORDERING.join(','));
  const selectedFilterItems = useSelector(
    (state) => state.filters.selectedFilterItems
  );
  const rowsPerPage = useSelector(
    (state) => state.staffingWorkPage.cache.assignmentsDetailsRowsPerPage
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const dispatch = useDispatch();
  const gridApiRef = useGridApiRef();

  const fetchPageOfJobs = () => {
    setIsLoading(true);
    dispatch(operations.fetchPageOfJobs({ batchId, page, rowsPerPage, sort }))
      .then(() => {
        setIsLoading(false);
        setIsErrored(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsErrored(true);
      });
  };

  const onRowsPerPageChange = (event) => {
    setPage(0);
    dispatch(setAssignmentsDetailsRowsPerPage(event.target.value));
  };

  const fieldsToWatch = [selectedFilterItems, rowsPerPage, sort];

  useEffect(() => {
    setPage(0);
  }, fieldsToWatch);

  useDeepCompareEffect(() => {
    fetchPageOfJobs();
  }, [...fieldsToWatch, page]);

  return (
    <Box
      css={{
        display: 'flex',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
      }}
    >
      <JobTable
        gridApiRef={gridApiRef}
        pageOfJobs={pageOfJobs}
        isLoading={isLoading}
        isErrored={isErrored}
        currentPage={page}
        onPageChange={(currentPage) => {
          setPage(currentPage);
        }}
        onSortChange={(ordering) => {
          setSort(ordering);
        }}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      <AssociateDrawer gridApiRef={gridApiRef} />
    </Box>
  );
}

export default AssignmentList;
