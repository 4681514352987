import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { format, utcToZonedTime } from 'date-fns-tz';

import { Box, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { HoverableDateTimeDiv } from '../style';
import JobTimePicker from './JobTimePicker';
import { convertMinsToHrsMins } from '../../../lib/utils';
import { determineDisabledText, determineShowDateTimePicker } from '../utils';

const ExpectedDuration = styled.div`
  display: inline;
  border-radius: 2px;
`;

function JobTimeInfo({
  id,
  status,
  startDate,
  endDate,
  storeTimezone,
  createdJobStartAfter,
  createdJobFinishBefore,
  eta = null,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
}) {
  const isCompletedDateTime = status === 'Completed';
  const isCanceled = status === 'Canceled';
  const isNotEditable = advantageSourceSystemName !== 'JET';
  const isDisabled = isCanceled || isNotEditable || isCompletedDateTime;
  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const startDateWithTimezone = utcToZonedTime(startDate, storeTimezone);
  const endDateWithTimezone = utcToZonedTime(endDate, storeTimezone);
  const timeZoneOptions = { timeZone: storeTimezone };
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const isSingleMode = Array.from(centralMode.values())[0] === 'single';

  const showTimePicker = determineShowDateTimePicker(
    isHovering,
    isActive,
    isLoading,
    !isDisabled,
    isSingleMode
  );

  const timeOut = (
    <>
      {format(startDateWithTimezone, 'h:mm a', timeZoneOptions)}
      {' - '}
      {format(endDateWithTimezone, 'h:mm a', timeZoneOptions)}{' '}
      <span>
        (
        {format(utcToZonedTime(startDate, storeTimezone), 'z', timeZoneOptions)}
        )
      </span>
    </>
  );

  return (
    <HoverableDateTimeDiv
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onBlur={() => {
        if (!isPickerOpen) {
          setIsActive(false);
        }
      }}
      className="job-time-wrapper"
    >
      {showTimePicker ? (
        <JobTimePicker
          id={id}
          value={startDateWithTimezone}
          minDateTime={utcToZonedTime(createdJobStartAfter, storeTimezone)}
          maxDateTime={utcToZonedTime(createdJobFinishBefore, storeTimezone)}
          advantageSourceSystemName={advantageSourceSystemName}
          externalIdentifier={externalIdentifier}
          getUpdatedJob={getUpdatedJob}
          storeTimezone={storeTimezone}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setIsActive={setIsActive}
          setIsPickerOpen={setIsPickerOpen}
          isPickerOpen={isPickerOpen}
          isActive={isActive}
          setIsHovering={setIsHovering}
        />
      ) : (
        <Tooltip
          title={determineDisabledText({
            isNotEditable,
            isCompletedDateTime,
            isCanceled,
          })}
        >
          <Box>
            <section>{timeOut}</section>
            {eta && (
              <ExpectedDuration title={convertMinsToHrsMins(eta)}>
                {convertMinsToHrsMins(eta, false, true)}
              </ExpectedDuration>
            )}
          </Box>
        </Tooltip>
      )}
    </HoverableDateTimeDiv>
  );
}

export default JobTimeInfo;
