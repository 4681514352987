import React, { useEffect } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import Feature from 'components/Feature';
import { AutoAwesomeOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedFilterItems } from 'state/ducks/filters/actions';
import {
  setBatchId,
  setBatchJobs,
  setIsSelectAllPages,
  setIsSelectAllPagesLoading,
  setSelectAllPageCount,
} from 'state/ducks/staffingWorkPage/actions';
import { BatchActionButton, BatchActionGroup } from './style';

function BatchModeActions({ setIsEditOpen }) {
  const dispatch = useDispatch();
  const assignmentsCount = useSelector(
    (state) => state.staffingWorkPage.batch.batchJobs
  ).length;
  const batchId = useSelector((state) => state.staffingWorkPage.batch.batchId);

  const disableEditButton = assignmentsCount === 0 && !batchId;
  const flagName = 'showAutoStaff';
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const showAutoStaff = useSelector(
    (state) => state.featureFlags.showAutoStaff
  );

  const currentMode = Array.from(centralMode.values())[0];
  const isBatchModeOn = currentMode === 'batch';
  const isSingleModeOn = currentMode === 'single';
  const isAutoModeOn = currentMode === 'auto';

  const handleSelectCentralMode = (filterOption) => {
    if (currentMode !== filterOption) {
      dispatch(
        updateSelectedFilterItems({
          centralMode: new Map([[filterOption, filterOption]]),
        })
      );
      dispatch(setBatchJobs([]));
      dispatch(setIsSelectAllPages(false));
      dispatch(setIsSelectAllPagesLoading(false));
      dispatch(setBatchId(null));
      dispatch(setSelectAllPageCount(0));
    }
  };

  useEffect(() => {
    if (showAutoStaff === false && currentMode === 'auto') {
      handleSelectCentralMode('single');
    }
  }, [showAutoStaff, centralMode]);

  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <BatchActionGroup showAutoStaff={showAutoStaff} size="small">
        <BatchActionButton
          selected={isSingleModeOn}
          value={isSingleModeOn}
          id="single_mode"
          onClick={() => handleSelectCentralMode('single')}
        >
          Single
        </BatchActionButton>
        <Tooltip
          title="JET assignments only. SRS and Retail Logic assignments cannot be edited in batch mode."
          placement="top"
          arrow
          PopperProps={{ style: { maxWidth: '250px' } }}
        >
          <BatchActionButton
            selected={isBatchModeOn}
            value={isBatchModeOn}
            id="batch_mode"
            onClick={() => handleSelectCentralMode('batch')}
          >
            Batch
          </BatchActionButton>
        </Tooltip>
        <Feature flagName={flagName}>
          <Tooltip
            title="Unstaffed JET assignments only. Any staffed assignment will not be shown."
            placement="top"
            arrow
            PopperProps={{ style: { maxWidth: '250px' } }}
          >
            <BatchActionButton
              selected={isAutoModeOn}
              value={isAutoModeOn}
              id="auto_mode"
              onClick={() => handleSelectCentralMode('auto')}
            >
              AI Assist
              <AutoAwesomeOutlined
                css={{
                  marginLeft: '4px',
                  width: '18.33px',
                  height: '18.33px',
                }}
              />
            </BatchActionButton>
          </Tooltip>
        </Feature>
      </BatchActionGroup>
      {isBatchModeOn && (
        <Button
          disabled={disableEditButton}
          variant="contained"
          id="batch_edit"
          size="medium"
          css={{ marginLeft: '24px' }}
          onClick={() => setIsEditOpen(true)}
        >
          EDIT
        </Button>
      )}
      {isAutoModeOn && (
        <Button
          disabled={disableEditButton}
          variant="contained"
          id="auto_staff"
          size="medium"
          css={{ marginLeft: '24px' }}
          onClick={() => setIsEditOpen(true)}
        >
          STAFF
        </Button>
      )}
    </Box>
  );
}

export default BatchModeActions;
