import React, { useState } from 'react';

import { Box, Tooltip } from '@mui/material';
import { utcToZonedTime, format } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { HoverableDateTimeDiv } from '../style';
import JobDatePicker from './JobDatePicker';
import { determineDisabledText, determineShowDateTimePicker } from '../utils';

function JobDateInfo({
  id,
  status,
  startDate,
  storeTimezone,
  createdJobStartAfter,
  createdJobFinishBefore,
  advantageSourceSystemName,
  externalIdentifier,
  getUpdatedJob,
}) {
  const isCompletedDateTime = status === 'Completed';
  const isCanceled = status === 'Canceled';
  const isNotEditable = advantageSourceSystemName !== 'JET';
  const isDisabled = isCanceled || isNotEditable || isCompletedDateTime;
  const [isHovering, setIsHovering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const startDateWithTimezone = utcToZonedTime(startDate, storeTimezone);
  const day = format(startDateWithTimezone, 'EEEE', {
    timeZone: storeTimezone,
  });
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const isSingleMode = Array.from(centralMode.values())[0] === 'single';

  const showDatePicker = determineShowDateTimePicker(
    isHovering,
    isActive,
    isLoading,
    !isDisabled,
    isSingleMode
  );

  return (
    <HoverableDateTimeDiv
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      onBlur={() => {
        if (!isPickerOpen) {
          setIsActive(false);
        }
      }}
      className="job-date-wrapper"
    >
      {showDatePicker ? (
        <JobDatePicker
          id={id}
          value={startDateWithTimezone}
          minDateTime={utcToZonedTime(createdJobStartAfter, storeTimezone)}
          maxDateTime={utcToZonedTime(createdJobFinishBefore, storeTimezone)}
          externalIdentifier={externalIdentifier}
          advantageSourceSystemName={advantageSourceSystemName}
          getUpdatedJob={getUpdatedJob}
          storeTimezone={storeTimezone}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setIsActive={setIsActive}
          isActive={isActive}
          setIsHovering={setIsHovering}
          setIsPickerOpen={setIsPickerOpen}
          isPickerOpen={isPickerOpen}
        />
      ) : (
        <Tooltip
          title={determineDisabledText({
            isNotEditable,
            isCompletedDateTime,
            isCanceled,
          })}
        >
          <Box>
            <section>
              {format(startDateWithTimezone, 'M/d/yyyy', {
                timeZone: storeTimezone,
              })}
            </section>
            {day}
          </Box>
        </Tooltip>
      )}
    </HoverableDateTimeDiv>
  );
}

export default JobDateInfo;
