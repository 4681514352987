/* eslint-disable camelcase */
import React from 'react';
import { Typography, Box } from '@mui/material';
import { capitalizeWordsInAllCaps } from '../../../lib/utils';

function HomeStoreLocation({ homeStoreLocation }) {
  return (
    <Box id="associate_details_home_store_location">
      <Typography variant="body2">
        {`${homeStoreLocation.chain_name} `}
        {homeStoreLocation.primary_self_identity
          ? `- ${homeStoreLocation.primary_self_identity}`
          : ''}
      </Typography>
      <Typography variant="body2">
        {homeStoreLocation.address.address}
      </Typography>
      <Typography variant="body2">
        {`${capitalizeWordsInAllCaps(
          homeStoreLocation.address.city
        )}, ${homeStoreLocation.address.state} ${homeStoreLocation.address.postal_code}`}
      </Typography>
    </Box>
  );
}

export default HomeStoreLocation;
