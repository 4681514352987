/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDeepCompareEffect from 'use-deep-compare-effect';
import { Box, Button, Divider, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { getJobDetails } from 'services/jobs';
import { updateSingleRow } from 'components/JobTable/utils';
import { enqueueSnackbar } from 'notistack';
import { DrawerErrorWrapper } from '../../styles/components';
import Header from './components/Header';
import { getMatchedAssociates, putAssociate } from '../../services/associates';
import { ErrorCloseButton } from './style';
import { DEFAULT_VIEW_ASSOCIATE } from '../../state/ducks/viewAssociate/constants';
import { ASSOCIATE_ASSIGNED_SUCCESS_TEXT } from '../../containers/Home/constants';
import { formatErrorFromSubsystem } from '../../lib/utils';
import {
  setSuggestAssociateJob,
  updateRowsPerPage,
} from '../../state/ducks/suggestAssociate/actions';
import { setViewAssociate } from '../../state/ducks/viewAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../state/ducks/suggestAssociate/constants';
import SelectedAssociate from './components/SelectedAssociate';
import Associates from './components/Associates';
import Footer from './components/Footer';
import { associateDrawerWidth } from './constants';
import { VARIANTS } from '../../constants';

function ShowError({ msg, doRetry, fullHeight = false }) {
  return (
    <DrawerErrorWrapper fullHeight={fullHeight} id="matched-associates-error">
      <p>{msg}</p>
      <button className="button" onClick={doRetry}>
        Retry
      </button>
    </DrawerErrorWrapper>
  );
}

let abortController = new AbortController();

function SuggestAssociates({ gridApiRef }) {
  const [page, setPage] = useState(0);
  const [associates, setAssociates] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const lastAdvancedSearchOptions = useSelector(
    (state) => state.suggestAssociate.advancedSearch
  );
  const [currentAdvancedSearchOptions, setCurrentAdvancedSearchOptions] =
    useState(lastAdvancedSearchOptions);
  const suggestAssociateJob = useSelector(
    ({ suggestAssociate: { job } }) => job
  );
  const dispatch = useDispatch();
  const jobId = suggestAssociateJob.id;

  const pageSize = useSelector(
    (state) => state.suggestAssociate.cache.rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const loadMatchedAssociates = () => {
    if (jobId) {
      abortController.abort();
      abortController = new AbortController();
      const { signal } = abortController;

      setErrorMessage(null);
      setIsLoading(true);

      getMatchedAssociates(
        {
          jobId,
          page: page + 1,
          pageSize,
          ...lastAdvancedSearchOptions,
        },
        signal
      )
        .then((data) => {
          setIsLoading(false);
          setAssociates(data.results);
        })
        .catch((error) => {
          if (signal && !signal.aborted) {
            setErrorMessage(
              error.message || 'There was a problem retrieving the Job record'
            );
            setIsLoading(false);
          }
        });
    }
  };

  const closeDrawer = () => {
    dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
    dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
  };

  const wipeState = () => {
    setAssociates([]);
    setErrorMessage(null);
    setIsLoading(true);
    setPage(0);
    dispatch(setViewAssociate(DEFAULT_VIEW_ASSOCIATE));
    dispatch(setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB));
  };

  const handleAssignAssociateClick = (associateId, ignoreWarnings = false) => {
    setIsLoading(true);
    if (jobId) {
      putAssociate({ associateId, jobId, ignoreWarnings })
        .then(() => {
          enqueueSnackbar(ASSOCIATE_ASSIGNED_SUCCESS_TEXT, {
            variant: VARIANTS.success,
          });
          closeDrawer();
          getJobDetails(jobId).then((updatedJob) => {
            updateSingleRow(gridApiRef, updatedJob);
          });
        })
        .catch((error) => {
          setIsLoading(false);
          enqueueSnackbar(
            formatErrorFromSubsystem(
              'Error when assigning or un-assigning',
              error.response.data.detail,
              suggestAssociateJob.external_identifier,
              suggestAssociateJob.advantage_source_system_name
            ),
            { variant: VARIANTS.error }
          );
        });
    }
  };

  useEffect(() => {
    setPage(0);
    setCurrentAdvancedSearchOptions(lastAdvancedSearchOptions);
  }, [suggestAssociateJob.id]);

  useDeepCompareEffect(() => {
    loadMatchedAssociates();
  }, [page, pageSize, lastAdvancedSearchOptions]);

  if (errorMessage) {
    return (
      <Box css={{ width: associateDrawerWidth }}>
        <ErrorCloseButton>
          <Button onClick={closeDrawer}>
            <CloseIcon />
          </Button>
        </ErrorCloseButton>
        <ShowError
          msg={errorMessage}
          doRetry={loadMatchedAssociates}
          fullHeight
        />
      </Box>
    );
  }
  return (
    <Box
      id="suggest-associates"
      css={{
        width: associateDrawerWidth,
        height: '100%',
        backgroundColor: 'rgba(11, 59, 96, 0.04)',
        overflowY: 'scroll',
      }}
    >
      <Header
        assignmentId={suggestAssociateJob.external_identifier}
        setPage={setPage}
        lastAdvancedSearchOptions={lastAdvancedSearchOptions}
        currentAdvancedSearchOptions={currentAdvancedSearchOptions}
        setCurrentAdvancedSearchOptions={setCurrentAdvancedSearchOptions}
        loadMatchedAssociates={() => {
          setPage(0);
          loadMatchedAssociates();
        }}
        isLoading={isLoading}
        jobId={suggestAssociateJob.id}
        onClose={wipeState}
        homeSystem={suggestAssociateJob.advantage_source_system_name}
      />
      <SelectedAssociate />
      <Divider />
      <Box
        css={{
          padding: '8px 16px',
        }}
      >
        <Typography variant="subtitle1">Match Results</Typography>
      </Box>
      <Divider />
      <Associates
        associates={associates}
        isLoading={isLoading}
        handleAssignAssociateClick={handleAssignAssociateClick}
      />
      <Footer
        page={page}
        rowsPerPage={pageSize}
        onChange={handleChangePage}
        onRowsPerPageChange={(event) => {
          setPage(0);
          dispatch(updateRowsPerPage(event.target.value));
        }}
      />
    </Box>
  );
}

export default SuggestAssociates;
