import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { NO_ASSOCIATE } from '../constants';

const getJobIds = (jobs) => jobs.map(({ id }) => id);
const getUserIds = (jobs) => jobs.map((job) => job.user?.id || null);

export const convertToMinutes = (hours, minutes) =>
  Number(hours) * 60 + Number(minutes);

export const convertToHoursAndMinutes = (minutes) => ({
  hours: String(Math.floor(Number(minutes) / 60)),
  minutes: String(Number(minutes) % 60),
});

export const determineAssociateToDisplay = (jobs, isSelectAllPages) => {
  if (isSelectAllPages) {
    return {
      name: '(Multiple Values)',
      ids: [],
      jobIds: [],
    };
  }
  const noJobs = jobs.length === 0;
  const associateNames = jobs.map((job) => job.user?.full_name || '');
  const uniqueAssociateNames = [...new Set(associateNames)];
  return noJobs
    ? NO_ASSOCIATE
    : {
        name:
          uniqueAssociateNames.length === 1
            ? uniqueAssociateNames[0]
            : '(Multiple Values)',
        ids: getUserIds(jobs),
        jobIds: getJobIds(jobs),
      };
};

const multipleValues = {
  view: '(Multiple Values)',
  edit: null,
};

export const determineDateToDisplay = (jobDates, isSelectAllPages) => {
  const dates = jobDates.map((date) => format(date, 'MM/dd/yyyy'));
  const uniqueDates = [...new Set(dates)];
  return isSelectAllPages || uniqueDates.length > 1
    ? multipleValues
    : { view: uniqueDates[0], edit: jobDates[0] };
};

export const determineTimeToDisplay = (jobTimes, isSelectAllPages) => {
  const times = jobTimes.map((time) => format(time, 'hh:mm a'));
  const uniqueTimes = [...new Set(times)];
  return isSelectAllPages || uniqueTimes.length > 1
    ? multipleValues
    : { view: uniqueTimes[0], edit: jobTimes[0] };
};

export const determineDurationView = (hours, minutes) => {
  const hoursView = hours === '0' ? '' : `${hours}h`;
  const minutesView = minutes === '0' ? '' : `${minutes}m`;
  return [hoursView, minutesView].filter((view) => view).join(' ');
};

export const determineDurationToDisplay = (jobDurations, isSelectAllPages) => {
  const uniqueDurations = [...new Set(jobDurations)];
  if (isSelectAllPages || uniqueDurations.length > 1) {
    return {
      ...multipleValues,
      edit: {
        hours: '',
        minutes: '',
      },
    };
  }
  const { hours, minutes } = convertToHoursAndMinutes(uniqueDurations[0]);
  return {
    view: determineDurationView(hours, minutes),
    edit: {
      hours,
      minutes,
    },
  };
};

export const determineAssignmentsSelectedLabel = (batchJobsCount) =>
  `Assignment${batchJobsCount > 1 ? 's' : ''} selected`;

export const isSaveDisabled = ({ associate, startDate, startTime, duration }) =>
  associate.old.name === associate.new.name &&
  startDate.old === startDate.new &&
  startTime.old === startTime.new &&
  duration.old === duration.new;

export const determineMutation = (associate, isBatchModeOn) => {
  if (isBatchModeOn) {
    if (!isEmpty(associate.old.name) && isEmpty(associate.new.name)) {
      return 'unassign';
    }
    if (associate.old.name !== associate.new.name) {
      return 'assign';
    }
    return 'update';
  }
  return 'auto';
};

export const determineRequest = (
  url,
  { associate, startDate, startTime, duration },
  jobIds,
  batchId,
  batchName
) => ({
  url,
  payload: {
    ...(associate.old.name === associate.new.name
      ? {}
      : { user_id: associate.new.ids[0] }),
    ...(batchId ? { job_list_id: batchId } : {}),
    ...(batchName ? { batch_name: batchName } : {}),
    ...(duration.old === duration.new ? {} : { duration: duration.new }),
    ...(jobIds.length > 0 ? { job_ids: jobIds } : {}),
    ...(startDate.old === startDate.new ? {} : { start_date: startDate.new }),
    ...(startTime.old === startTime.new ? {} : { start_time: startTime.new }),
  },
});
