import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { capitalizeWordsInAllCaps } from 'lib/utils';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AssociateContactInfoRow from '../components/AssociateContactInfoRow';

import { AssociateLeftColumn, AssociateInfoWrapper, BlockSpan } from '../style';

import { hasAddressInfo } from '../utils';
import SkeletonAssociateContact from '../components/SkeletonAssociateContact';

function AssociateContact() {
  const { associateContext } = useOutletContext();
  const { associate, isLoading } = associateContext;
  const theme = useTheme();

  if (isLoading) return <SkeletonAssociateContact />;

  if (!associate)
    return (
      <AssociateInfoWrapper className="associate-contact">
        <AssociateLeftColumn>
          <Typography variant="body1" css={{ margin: 'auto' }}>
            No information to display
          </Typography>
        </AssociateLeftColumn>
        <Box
          css={{
            backgroundColor: theme.palette.backgroundColor.primary,
            width: '100%',
          }}
        />
      </AssociateInfoWrapper>
    );

  return (
    <AssociateInfoWrapper className="associate-contact">
      <AssociateLeftColumn>
        <AssociateContactInfoRow
          id="associate-email"
          hasInfo={associate.email}
          message="No email provided"
          label="Email"
          type="email"
          href={`mailto:${associate.email}`}
        >
          {associate.email}
        </AssociateContactInfoRow>
        <AssociateContactInfoRow
          id="associate-phone_number"
          hasInfo={associate.phone_number}
          message="No phone number provided"
          label="Phone"
        >
          {associate.phone_number}
        </AssociateContactInfoRow>
        <AssociateContactInfoRow
          id="associate-address"
          hasInfo={hasAddressInfo(associate.profile)}
          message="No address provided"
          label="Address"
        >
          {associate.profile.address}
          <BlockSpan>
            {associate.profile.city && `${associate.profile.city}`}
            {associate.profile.state && `, ${associate.profile.state}`}
            {associate.profile.postal_code &&
              `, ${associate.profile.postal_code}`}
          </BlockSpan>
        </AssociateContactInfoRow>
        <AssociateContactInfoRow
          id="associate-division_name"
          hasInfo={associate.division_name}
          message="No Team / Retail provided"
          label="Team / Retailer"
        >
          {associate.division_name}
        </AssociateContactInfoRow>
        <AssociateContactInfoRow
          id="associate-home_store_location"
          hasInfo={associate.home_store_location}
          message="No home store provided"
          label="Home Store"
        >
          {associate.home_store_location && (
            <>
              <BlockSpan>
                {`${associate.home_store_location.chain_name} `}
                {associate.home_store_location.primary_self_identity
                  ? `- ${associate.home_store_location.primary_self_identity}`
                  : ''}
              </BlockSpan>
              <BlockSpan>
                {associate.home_store_location.address.address}
              </BlockSpan>
              {`${capitalizeWordsInAllCaps(
                associate.home_store_location.address.city
              )}, ${associate.home_store_location.address.state} ${
                associate.home_store_location.address.postal_code
              }`}
            </>
          )}
        </AssociateContactInfoRow>
      </AssociateLeftColumn>

      <Box
        css={{
          backgroundColor: theme.palette.backgroundColor.primary,
          width: '100%',
        }}
      />
    </AssociateInfoWrapper>
  );
}

export default AssociateContact;
