import { URL } from 'core/config';
import HTTP from '../../../core/http';

import * as actions from './actions';
import { abort } from '../abort/operations';

export const fetchAssociates =
  (page, sort, rowsPerPage, associateIds, managerId, showUnreadChanges) =>
  async (dispatch, getState) => {
    const sectionKey = 'fetchAssociates';
    const endpoint = URL.associates;
    await dispatch(abort(sectionKey));
    const {
      abort: { abortControllers },
    } = getState();
    const { signal } = abortControllers[sectionKey];
    const params = {
      page_size: rowsPerPage,
      page: page + 1,
      ordering: sort,
      ids: associateIds,
      manager_user_id: managerId,
      status: 'active',
      include_unread_availability_event_indicator: true,
      only_unread_availability_events: showUnreadChanges,
    };
    return HTTP.get(endpoint, {
      params,
      signal,
    })
      .then((response) => {
        dispatch(
          actions.setAssociates(
            response.data.results.map((result) => ({
              id: result.external_id,
              name: result.full_name,
              phone: result?.phone_number || '',
              email: result.email,
              uniqueId: result.id,
              hasUnreadChanges: result.has_unread_availability_events,
            }))
          )
        );
      })
      .catch(() => {
        if (signal && !signal.aborted) {
          throw new Error('Error while fetching associates');
        }
      });
  };
