export default function safariTrailingSlashInterceptorFix(axiosInstance) {
  return axiosInstance.interceptors.request.use((config) => {
    const newConfig = Object.assign({}, config);
    const JUST_QUESTION = '\\?';

    if (newConfig.url) {
      if (newConfig.url.includes('/?')) {
        // has slash in correct place before query params
        // no-op
        return newConfig;
      }
      if (newConfig.url.includes('?')) {
        // if we got here we have a first question mark with no preceding slash, so fix
        newConfig.url = newConfig.url.replace(JUST_QUESTION, '/?');
      } else if (!newConfig.url.endsWith('/')) {
        // case for no query params
        newConfig.url += '/';
      }
    }

    return newConfig;
  });
}
