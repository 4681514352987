import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchFeatureFlag } from 'state/ducks/featureFlags/operations';
import JobsList from './components/JobsList';
import FilterDrawer, { DEFAULT_SELECTED_FILTERS } from '../Filters';
import PageLayout from '../../components/PageLayout';
import CallToActionBar from '../CallToActionBar';
import BatchActionBar from '../BatchActionBar';

function HomeContainer() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFeatureFlag('showAutoStaff'));
  }, []);

  return (
    <PageLayout hasFilters>
      <BatchActionBar />
      <CallToActionBar />
      <Box
        css={{
          display: 'flex',
          overflow: 'hidden',
          height: '100%',
          width: '100%',
        }}
      >
        <JobsList />
        <FilterDrawer defaultFilters={DEFAULT_SELECTED_FILTERS} />
      </Box>
    </PageLayout>
  );
}

export default HomeContainer;
