import { enqueueSnackbar } from 'notistack';
import HTTP from '../../../core/http';
import { URL } from '../../../core/config';
import { formatFilterCollectionToQueryParams } from '../../../lib/utils/queryParamUtils';
import { stringify } from '../../../lib/utils';
import { setPageOfJobs } from './actions';
import {
  DEFAULT_SORT_ORDERING,
  DEFAULT_STAFFING_WORK_PAGE_SIZE,
} from './constants';
import { abort } from '../abort/operations';
import { VARIANTS } from '../../../constants';

export const fetchPageOfJobs =
  ({
    batchId,
    page = 0,
    rowsPerPage = DEFAULT_STAFFING_WORK_PAGE_SIZE,
    sort = DEFAULT_SORT_ORDERING.join(','),
  }) =>
  async (dispatch, getState) => {
    const resource = 'fetchPageOfJobs';
    await dispatch(abort(resource));
    const {
      filters: { selectedFilterItems },
      abort: { abortControllers },
    } = getState();
    const { signal } = abortControllers[resource];

    const { central_mode: centralMode, ...otherFilterItems } =
      formatFilterCollectionToQueryParams(selectedFilterItems);

    const params = {
      page_size: rowsPerPage,
      page: page + 1,
      ordering: sort,
      ...(batchId ? { batch__in: batchId } : { ...otherFilterItems }),
      ...(centralMode !== 'single' && { central_mode: centralMode }),
    };

    const paramEntries = Object.entries(params);
    const data = stringify(paramEntries);
    const queryParamLength = data.length;
    if (queryParamLength > 4000) {
      enqueueSnackbar(
        'The filter limit has been reached. Please reduce your filters to resolve the error.',
        { variant: VARIANTS.error }
      );
    }
    const endpoint = URL.jobs;
    return HTTP.get(endpoint, {
      params,
      signal,
    })
      .then((response) => {
        dispatch(setPageOfJobs(response.data.results));
      })
      .catch(() => {
        if (signal && !signal.aborted) {
          throw new Error('Error while fetching page of jobs');
        }
      });
  };
