import { Box, Divider } from '@mui/material';
import React from 'react';
import { defaultColor } from '../../../styles/theme';
import { associateDrawerWidth } from '../constants';
import { SuggestAssociatesPagination } from '../style';
import { rowsPerPageOptions } from '../../../constants';

function Footer({
  associatesCount,
  page,
  onChange,
  rowsPerPage,
  onRowsPerPageChange,
}) {
  if (associatesCount === 0) {
    return null;
  }
  return (
    <Box
      css={{
        bottom: 0,
        position: 'fixed',
        width: associateDrawerWidth,
        backgroundColor: defaultColor,
      }}
    >
      <Divider />
      <Box
        css={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <SuggestAssociatesPagination
          component="div"
          id="suggest_associate_pagination"
          page={page}
          count={1000000000}
          onPageChange={onChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => onRowsPerPageChange(event)}
          rowsPerPageOptions={rowsPerPageOptions}
          labelDisplayedRows={({ from, to }) => `${from}–${to}`}
        />
      </Box>
    </Box>
  );
}

export default Footer;
