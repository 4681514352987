/* eslint-disable camelcase */
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Divider, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ASSOCIATE_ASSIGNED_SUCCESS_TEXT } from 'containers/Home/constants';
import { enqueueSnackbar } from 'notistack';
import LoadingIndicator from './LoadingIndicator';
import SearchAssociate from './SearchAssociate';
import Jyver from './Jyver';
import { putAssociate, removeAssociate } from '../../../services/associates';
import { formatErrorFromSubsystem } from '../../../lib/utils';
import { setSuggestAssociateJob } from '../../../state/ducks/suggestAssociate/actions';
import { DEFAULT_SUGGEST_ASSOCIATE_JOB } from '../../../state/ducks/suggestAssociate/constants';
import { determineDisabledText, determineShowAssign } from '../utils';
import { VARIANTS } from '../../../constants';

function Associate({ job, getUpdatedJob }) {
  const isCanceled = job.status_central === 'Canceled';
  const isCompleted = job.status_central === 'Completed';
  const [isHovering, setIsHovering] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = job;
  const centralMode = useSelector(
    (state) => state.filters.selectedFilterItems.centralMode
  );
  const isSingleMode = Array.from(centralMode.values())[0] === 'single';

  const showAssign = determineShowAssign(
    isHovering,
    isActive,
    isLoading,
    !isCanceled,
    !isCompleted,
    isSingleMode
  );
  const disabled =
    job.status_central === 'Completed' || job.status_central === 'Canceled';
  const searchInitialValue = user ? user.full_name : null;
  const jobId = job.id;

  const handleAssociateClick = (
    associateId,
    errorCallback,
    ignoreWarnings = false
  ) => {
    setIsLoading(true);
    putAssociate({ associateId, jobId, ignoreWarnings })
      .then(() => {
        enqueueSnackbar(ASSOCIATE_ASSIGNED_SUCCESS_TEXT, {
          variant: VARIANTS.success,
        });
        setIsLoading(false);
        setIsActive(false);
        getUpdatedJob(jobId);
      })
      .catch((error) => {
        enqueueSnackbar(
          formatErrorFromSubsystem(
            'Error when assigning or un-assigning',
            error.response.data.detail,
            job.external_identifier,
            job.advantage_source_system_name
          ),
          { variant: VARIANTS.error }
        );
        setIsLoading(false);
        setIsActive(true);
        errorCallback();
      });
  };

  return (
    <Tooltip
      title={determineDisabledText({
        isCanceled,
        isCompletedAssignment: isCompleted,
      })}
      arrow
    >
      <Box
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        className="job-associate-column"
        css={{ height: '100%' }}
      >
        {user && !showAssign ? (
          <Jyver
            jobId={job.id}
            avatarUrl={user.photo_thumb_url || ''}
            fullName={user.full_name}
            phone={user.phone_number}
            jobsCompleted={user.jobs_completed}
            title={user.title}
            divisionName={user.division_name}
            employeeId={user.external_id}
          />
        ) : (
          <Box
            css={{
              height: '100%',
            }}
            className="search-associates"
          >
            {showAssign && (
              <>
                <Box
                  className="search-associates-wrapper"
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <SearchAssociate
                    sx={{
                      width: '100%',
                    }}
                    value={searchInitialValue}
                    isActive={(newIsActive) => {
                      setIsActive(newIsActive);
                    }}
                    disabled={disabled}
                    onChange={({ id }, errorCallback) =>
                      handleAssociateClick(id, errorCallback)
                    }
                  />
                  {isLoading && (
                    <Box
                      css={{
                        margin: '7px 9.75px',
                      }}
                    >
                      <LoadingIndicator />
                    </Box>
                  )}
                  {user && !isLoading && (
                    <IconButton
                      className="associate-unassign-button"
                      onClick={() => {
                        setIsLoading(true);
                        removeAssociate(job.id)
                          .then(() => {
                            enqueueSnackbar(
                              'Associate was removed successfully!',
                              { variant: VARIANTS.success }
                            );
                            setIsLoading(false);
                            getUpdatedJob(job.id);
                          })
                          .catch((error) => {
                            enqueueSnackbar(
                              formatErrorFromSubsystem(
                                'Error when assigning or un-assigning',
                                error.response.data.detail,
                                job.external_identifier,
                                job.advantage_source_system_name
                              ),
                              { variant: VARIANTS.error }
                            );
                            setIsLoading(false);
                          });
                      }}
                      disabled={disabled}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
                <Divider />
                <Button
                  variant="text"
                  className="associate-suggest-button"
                  onClick={() => {
                    Promise.resolve(
                      dispatch(
                        setSuggestAssociateJob(DEFAULT_SUGGEST_ASSOCIATE_JOB)
                      )
                    ).then(() => {
                      dispatch(setSuggestAssociateJob(job));
                    });
                  }}
                  disabled={disabled}
                  css={{
                    marginTop: '8px',
                    width: '100%',
                  }}
                >
                  Suggest Associate
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}

export default Associate;
