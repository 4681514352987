import store from 'store2';

import { updateAbilityByUser, clearAbility } from '../../core/ability';
import User from '../../lib/user';
import HTTP from '../../core/http';
import {
  URL,
  JWT_PAYLOAD_LOCAL_STORAGE_KEY,
  USER_TIMEZONE_KEY,
} from '../../core/config';
import { removeAllFromCacheExceptStartsWith } from '../cache';

export default class AuthService {
  static accessTokenPayload = null;

  static userTimezone = null;

  static setAccessTokenPayload(token) {
    // JWT's are comprised of 3 parts, header, payload and signature
    // we only want to save the payload bit, so we grab index 1 here
    const payload = token.split('.')[1];

    store.set(JWT_PAYLOAD_LOCAL_STORAGE_KEY, payload);
    this.accessTokenPayload = payload;

    // Every time we update the access payload in localStorage we
    // want to make sure we update all related services in our app
    // with the latest information we have on the user:
    const currentUser = this.getCurrentUser();

    if (currentUser) {
      // update the users access permissions
      updateAbilityByUser(currentUser);
    }
  }

  static setUserTimezone(payload) {
    store.set(USER_TIMEZONE_KEY, payload);
    this.userTimezone = payload;
  }

  static getAccessTokenPayload() {
    if (!this.accessTokenPayload) {
      this.accessTokenPayload = store.get(JWT_PAYLOAD_LOCAL_STORAGE_KEY);
    }
    return this.accessTokenPayload || null;
  }

  static getUserTimezone() {
    if (!this.userTimezone) {
      this.userTimezone = store.get(USER_TIMEZONE_KEY);
    }
    return this.userTimezone || null;
  }

  static getDecodedAccessTokenPayload() {
    try {
      if (this.getAccessTokenPayload()) {
        return JSON.parse(window.atob(this.getAccessTokenPayload()));
      }

      return null;
    } catch (error) {
      throw new Error('Unable to decode user payload');
    }
  }

  static async refreshAccessToken() {
    if (!this.getAccessTokenPayload())
      return Promise.reject(new Error('No user info found in local storage!'));

    try {
      const {
        data: { access },
      } = await HTTP.post(URL.tokenRefresh);
      this.setAccessTokenPayload(access);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  static login(accessToken) {
    return HTTP.post(URL.login, {
      okta_access_token: accessToken,
    })
      .then((result) => {
        const token = result.data.access;
        const { timezone } = result.data.user.profile;

        try {
          this.setAccessTokenPayload(token);
          this.setUserTimezone(timezone);
        } catch (error) {
          return Promise.reject(error);
        }

        return Promise.resolve();
      })
      .catch((error) => Promise.reject(error));
  }

  static async processOktaLogin() {
    const oktaToken = this.getOktaTokenFromLocalStorage();
    await this.login(oktaToken);
  }

  static getOktaTokenFromLocalStorage() {
    const token = localStorage.getItem('okta-token-storage');
    if (token) {
      return JSON.parse(token).accessToken.accessToken;
    }
    return '';
  }

  static async logout() {
    try {
      // clear out access token from memory
      this.accessTokenPayload = null;

      removeAllFromCacheExceptStartsWith(['boostRetail']);

      // have the API clear out our HttpOnly cookies
      await HTTP.post(URL.logout);

      // clear the users abilities
      clearAbility();

      return true;
    } catch (error) {
      return false;
    }
  }

  static getCurrentUser = () => {
    try {
      const currentUser = this.getDecodedAccessTokenPayload();
      const timezone = this.getUserTimezone();
      if (currentUser) {
        const {
          user_id: id,
          name: fullName,
          picture: avatar,
          user_groups: groups,
          is_staff: isStaff,
          email,
        } = currentUser;

        return new User({
          id,
          fullName,
          email,
          avatar,
          groups,
          isStaff,
          timezone,
        });
      }

      return null;
    } catch (error) {
      return null;
    }
  };
}
