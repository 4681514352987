import { EXCEPTIONS_TO_MATCH_DETAILS } from '../../SuggestAssociates/constants';

export const iconDetails = {
  missing: {
    color: 'red',
    icon: 'error',
  },
  matching: {
    color: 'green',
    icon: 'check_circle',
  },
};

export const determineIconDetail = (exception, exceptions) =>
  iconDetails[
    exceptions.includes(exception) ||
    (exception === 'is_available' && exceptions.includes('has_remaining_hours'))
      ? 'missing'
      : 'matching'
  ];

export const determineExceptionText = (exceptionText, text, iconText) => {
  const declinedExceptionText =
    EXCEPTIONS_TO_MATCH_DETAILS.previously_declined.exception_text;
  return exceptionText === declinedExceptionText && iconText === 'error'
    ? 'Has declined this assignment'
    : text;
};

export const determineAddress = (city, state, zipcode) => {
  const addressParts = [city, state, zipcode].filter(Boolean);

  return addressParts.join(', ');
};

export const determineHomeStoreAddress = (
  chainName,
  primarySelfIdentity,
  city,
  state
) => {
  const homeStoreParts = [chainName, primarySelfIdentity, city].filter(Boolean);

  if (homeStoreParts.length === 0) {
    return state;
  }

  return state
    ? `${homeStoreParts.join(' - ')}, ${state}`
    : homeStoreParts.join(' - ');
};
