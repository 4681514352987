import React from 'react';
import { ViewIcon } from './components/ViewIcon';
import { UnreadIcon } from './components/UnreadIcon';

export const sortOptions = {
  id: ['profile__external_id'],
  name: ['first_name', 'last_name'],
  email: ['email'],
  phone: ['profile__phone_number'],
};

const unreadColumnWidth = 32;
export const columns = [
  {
    field: 'unread',
    headerName: '',
    sortable: false,
    width: unreadColumnWidth,
    minWidth: unreadColumnWidth,
    renderCell: ({ row: { hasUnreadChanges } }) =>
      hasUnreadChanges ? (
        <UnreadIcon
          cssOverride={{
            marginLeft: '8px',
          }}
        />
      ) : null,
  },
  {
    field: 'name',
    headerName: 'NAME',
    minWidth: 300,
    flex: 1,
    sortOrder: 'name',
  },
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 120,
    flex: 1,
    sortOrder: 'id',
  },
  {
    field: 'phone',
    headerName: 'PHONE',
    minWidth: 275,
    flex: 1,
    sortOrder: 'phone_number',
  },
  {
    field: 'email',
    headerName: 'EMAIL',
    minWidth: 110,
    flex: 1,
    sortOrder: 'email',
  },
  {
    field: 'view',
    headerName: 'VIEW',
    sortable: false,
    width: 130,
    renderCell: () => <ViewIcon />,
  },
];
