import HTTP from '../../../core/http';
import * as actions from './actions';
import { abort } from '../abort/operations';

export const fetchPageOfBatches =
  (page, rowsPerPage, id, status) => async (dispatch, getState) => {
    const sectionKey = 'fetchPageOfBatches';

    await dispatch(abort(sectionKey));
    const {
      abort: { abortControllers },
    } = getState();
    const { signal } = abortControllers[sectionKey];
    const params = {
      page_size: rowsPerPage,
      page: page + 1,
      ...(status !== 'total' && { status__in: status }),
    };

    return HTTP.get(`/bulk/batch/${id}/rows/`, {
      signal,
      params,
    })
      .then((response) => {
        dispatch(actions.setBatchDetails(response.data.results));
      })
      .catch(() => {
        if (signal && !signal.aborted) {
          throw new Error('Error while fetching batch rows');
        }
      });
  };

export const fetchBatchDetails = (id) => async (dispatch, getState) => {
  const sectionKey = 'fetchBatchDetails';

  await dispatch(abort(sectionKey));
  const {
    abort: { abortControllers },
  } = getState();
  const { signal } = abortControllers[sectionKey];

  return HTTP.get(`/bulk/batch/${id}/`, {
    signal,
  })
    .then((response) => {
      dispatch(actions.setBatch(response.data));
    })
    .catch(() => {
      if (signal && !signal.aborted) {
        throw new Error('Error while fetching batch');
      }
    });
};
