import * as React from 'react';
import { Box, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AlertDialog from '../Dialog/AlertDialog';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import Menu from '../../containers/Menu';
import Header from '../../containers/Header';
import BatchDialog from '../Dialog/BatchDialog';

function PageLayout({ children, hasFilters = false, cssClassName }) {
  const theme = useTheme();
  return (
    <>
      <AlertDialog />
      <BatchDialog />
      <ConfirmationDialog />
      <Menu />
      <Header hasFilters={hasFilters} />
      <Box
        as="main"
        css={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.backgroundColor.primary,
          ...cssClassName,
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </>
  );
}

export default PageLayout;
