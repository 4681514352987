import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as types from './types';
import { DEFAULT_STAFFING_WORK_PAGE_SIZE } from './constants';

const pageOfJobsReducer = (
  // eslint-disable-next-line default-param-last
  state = [],
  action
) => {
  switch (action.type) {
    case types.SET_PAGE_OF_JOBS:
      return action.payload;
    default:
      return state;
  }
};

const batchReducer = (
  // eslint-disable-next-line default-param-last
  state = {
    isSelectAllPages: false,
    isSelectAllPagesLoading: false,
    selectAllPagesCount: 0,
    batchJobs: [],
    batchId: null,
  },
  action
) => {
  switch (action.type) {
    case types.SET_BATCH_JOBS:
      return { ...state, batchJobs: action.payload };
    case types.SET_IS_SELECT_ALL_PAGES:
      return { ...state, isSelectAllPages: action.payload };
    case types.SET_IS_SELECT_ALL_PAGES_LOADING:
      return { ...state, isSelectAllPagesLoading: action.payload };
    case types.SET_SELECT_ALL_PAGES_COUNT:
      return { ...state, selectAllPagesCount: action.payload };
    case types.SET_BATCH_ID:
      return { ...state, batchId: action.payload };
    default:
      return state;
  }
};

const cacheReducer = persistReducer(
  {
    key: 'staffingWork',
    storage,
  },
  (
    // eslint-disable-next-line default-param-last
    state = {
      staffingWorkRowsPerPage: DEFAULT_STAFFING_WORK_PAGE_SIZE,
      assignmentsDetailsRowsPerPage: DEFAULT_STAFFING_WORK_PAGE_SIZE,
    },
    action
  ) => {
    switch (action.type) {
      case types.SET_STAFFING_WORK_ROWS_PER_PAGE:
        return {
          ...state,
          staffingWorkRowsPerPage: action.payload,
        };
      case types.SET_ASSIGNMENTS_DETAILS_ROWS_PER_PAGE:
        return {
          ...state,
          assignmentsDetailsRowsPerPage: action.payload,
        };
      default:
        return state;
    }
  }
);

export default combineReducers({
  batch: batchReducer,
  pageOfJobs: pageOfJobsReducer,
  cache: cacheReducer,
});
