import * as React from 'react';
import { useSelector } from 'react-redux';

import Drawer from '@mui/material/Drawer';

import { Toolbar } from '@mui/material';
import ClearAllWrapper from './components/ClearAllWrapper';

import sectionStructure from './utils/sectionStructure';
import DatesSelector from './components/DatesSelector';
import { filterDrawerWidth } from '../../styles/theme';

export { DEFAULT_SELECTED_FILTERS } from './constants';

function FilterDrawer() {
  const isOpen = useSelector((state) => state.filters.visibility.isOpen);
  const sx = {
    // zIndex: 1075,
    width: filterDrawerWidth,
  };
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      variant="persistent"
      sx={sx}
      PaperProps={{
        sx,
      }}
      id="filter-drawer"
    >
      <Toolbar />
      <DatesSelector />
      {Object.entries(sectionStructure).map(([key, { Component, ...rest }]) => (
        <Component key={`filter-section-${key}`} sectionKey={key} {...rest} />
      ))}
      <ClearAllWrapper />
    </Drawer>
  );
}

export default FilterDrawer;
